<template>
  <v-layout column>
    <FieldHeader
      v-if="!hideHeader"
      :field="field"
      :patient="patient"
      :session.sync="session"
      :owner="owner"
      :hideCollapseBtn="hideCollapseBtn"
      :collapsed="hideBody"
      @history="onHistory"
      @collapse="onCollapse"
      :status="status"
      :name="name"
      autosave
    ></FieldHeader>
    <v-layout column v-if="!disabled && !hideBody">
      <v-layout class="mb-6" column>
        <v-spacer v-if="!$vuetify.breakpoint.smAndDown"></v-spacer>
        <DefaultSubtitle class="mr-3 my-3">Porównanie</DefaultSubtitle>
        <v-radio-group
          v-model="internalComparison"
          class="mt-0"
          hide-details
          :row="!$vuetify.breakpoint.smAndDown"
        >
          <v-radio
            class="zg-text-control mt-0"
            label="Powierzchniowa/Głęboka strona"
            :value="0"
          ></v-radio>
          <v-radio
            class="zg-text-control mt-0"
            label="Prawa/Lewa strona"
            :value="1"
          ></v-radio>
        </v-radio-group>
      </v-layout>
      <PerfOxfTable :value.sync="data"></PerfOxfTable>
      <v-layout align-start class="mt-2">
        <v-icon class="default-icon">mdi-help-circle-outline</v-icon>
        <v-layout column>
          <div class="scale-title">SKALA OXFORD (w modyfikacji Laylock'a)*</div>
          <div class="scale">0 - Brak napięcia mięśni</div>
          <div class="scale">1 - Ślad skurczu (drżenia mięśniowe)</div>
          <div class="scale">
            2 - Słabe napięcie mięśni (niemożliwe do utrzymania)
          </div>
          <div class="scale">3 - Umiarkowany skurcz mięśni</div>
          <div class="scale">4 - Dobry (wyraźny) skurcz mięśniowy</div>
          <div class="scale">5 - Silny skurcz mięśni</div>
        </v-layout>
      </v-layout>
    </v-layout>
    <DisabledFieldWrapper
      v-else-if="!hideBody"
      :field="field"
      :hideEdit="hideEdit"
      :session="session"
      :owner="owner"
      @edit="onEdit"
      @append="onAppend"
    >
      <PerfOxfTable :value="data" class="my-2" disabled></PerfOxfTable>
    </DisabledFieldWrapper>
  </v-layout>
</template>

<script>
import ModificationMixin from "@/mixins/ModificationMixin";

export default {
  mixins: [ModificationMixin],
  props: {
    field: {},
    session: {},
    patient: {},
    owner: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hideCollapseBtn: {
      type: Boolean,
      default: false,
    },
    hideEdit: {
      type: Boolean,
      default: false,
    },
    hideBody: {
      type: Boolean,
      default: false,
    },
    syncId: {
      type: String,
      default: "patientCard",
    },
    clientOnlyPredefined: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
  },
  computed: {
    internalComparison: {
      get() {
        if (this.field.data.comparison) {
          return this.field.data.comparison;
        } else {
          return 0;
        }
      },
      set(value) {
        this.$set(this.field.data, "comparison", value);
      },
    },
    data: {
      get() {
        if (!this.field.data) {
          this.$set(this.field, "data", {
            date: "",
            comment: "",
            comparison: 0,
            power: {
              p: "",
              g: "",
            },
            endurance: {
              p: "",
              g: "",
            },
            repetitions: {
              p: "",
              g: "",
            },
            fast: {
              p: "",
              g: "",
            },
            evaluation: {
              p: "",
              g: "",
            },
            contraction: {
              p: "",
              g: "",
            },
            timing: {
              p: "",
              g: "",
            },
          });
        }
        return this.field.data;
      },
      set(value) {
        this.$set(this.field, "data", value);
      },
    },
    dataJson: {
      get() {
        return JSON.stringify(this.data);
      },
    },
  },
  components: {
    DefaultSubtitle: () => import("@/components/text/DefaultSubtitle"),
    FieldHeader: () =>
      import("@/components/delegates/patientcard/fields/FieldHeader"),
    DisabledFieldWrapper: () =>
      import("@/components/delegates/patientcard/fields/DisabledFieldWrapper"),
    PerfOxfTable: () => import("@/components/tables/patientcard/PerfOxfTable"),
  },
  methods: {
    onEdit() {
      this.$emit("edit");
    },
    onHistory() {
      this.$emit("history");
    },
    onCollapse() {
      this.$emit("collapse");
    },
    onAppend(data) {
      this.$emit("append", data);
    },
  },
  mounted() {
    if (!this.disabled && this.autosave) {
      this.startWatching("dataJson");
    }
  },
};
</script>

<style lang="scss" scoped>
.pch-text-wrapper {
  border-radius: 15px;
  background-color: white;
}
.pch-text {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
.pch-text ::v-deep p {
  margin-bottom: 0 !important;
}
.default-shadow {
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1) !important;
}
.scale-title {
  font-weight: bold !important;
  font-size: 13px !important;
  line-height: 20px !important;
  color: black !important;
}
.scale {
  font-size: 12px !important;
  line-height: 18px !important;
  color: black !important;
}
.default-icon {
  margin-top: 2px;
  font-size: 15px !important;
  min-width: 16px !important;
  height: 16px !important;
  color: var(--v-icons-base) !important;
}
.zg-text-control ::v-deep label {
  color: rgba(0, 0, 0, 0.87);
}
</style>